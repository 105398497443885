import { combineReducers } from 'redux'
import { reducers } from '@labelinsight/app-shell'
import locationReducer from './location'
import { reducer as app } from './app'

export const makeRootReducer = (asyncReducers) =>
  combineReducers({
    app,
    location: locationReducer,
    ...reducers,
    ...asyncReducers,
  })

export const injectReducer = (store, { key, reducer }) => {
  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
