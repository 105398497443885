import { createGlobalStyle } from 'styled-components'
import vars, { typography } from '@labelinsight/components/styles'

export const TypographyStyles = typography.TypographyStyles

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    height: 100%;
  }

  body {
    overflow: hidden;
    height: 100%;
    padding: ${vars.paddingCoreViewportTop} 0 0;
    margin: 0;
    font-family: 'Open Sans', 'Helvetica Neue', Helmet, Freesans, sans-serif;
  }

  #root {
    height: 100%;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
    font-family: 'Open Sans', 'Helvetica Neue', Helmet, Freesans, sans-serif !important;
  }

  .AppShell {
    height: calc(100vh - 70px);
  }
`
