import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import LIThemeProvider from '@labelinsight/components/LIThemeProvider'
import { GlobalStyle, TypographyStyles } from './styles/global'

App.propTypes = {
  store: PropTypes.object.isRequired,
  router: PropTypes.element.isRequired,
}

export default function App({ store, router }) {
  return (
    <Fragment>
      <TypographyStyles />
      <GlobalStyle />
      <Provider store={store}>
        <LIThemeProvider>{router}</LIThemeProvider>
      </Provider>
    </Fragment>
  )
}
