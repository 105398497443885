import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import {
  configure,
  util as appShellUtil,
  RequireAuth,
} from '@labelinsight/app-shell'
import { log } from 'li-error-logger/lib'
import history from '$history'

import Register from './Register'
import ResetPassword from './ResetPassword'
import UpdatePassword from './UpdatePassword'
import VerifyToken from './VerifyToken'

const registrationCampaignConfigurations = {
  instacart: {
    firstTimeLoginRedirectPath: '/onboard/instacart',
  },
}

const getRegistrationCampaignConfiguration = (registrationCampaign) =>
  registrationCampaignConfigurations[registrationCampaign] || {}

export default (store) => {
  const appId = 'home'

  const config = {
    appId,
    onFullyAuthenticated() {
      let href

      try {
        const state = store.getState()
        const user = appShellUtil.getUser()

        href = getRedirect({
          location,
          user,
          isFirstTimeVerifiedLogin: state.app.isFirstTimeVerifiedLogin,
        })

        if (location.href.includes('localhost')) {
          // eslint-disable-next-line no-alert
          alert(`mock redirect to ${href}`)
        } else {
          location.href = href
        }
      } catch (error) {
        log(error, {
          from: 'onFullyAuthenticated',
          href,
        })
      }
    },
    initialAppShellUiState: {
      internalDisplayName: 'home',
    },
    useRelativeRegistrationLink: true,
    useRelativePasswordResetLink: true,
    shouldInjectGlobalStyles: false,
  }

  const { Shell } = configure(store, config)

  function ForceRedirectToDefaultApplication() {
    useEffect(() => {
      config.onFullyAuthenticated()
    }, [])
    return null
  }

  return (
    <Shell history={history}>
      <Route
        path="/register/:registrationType?/:registrationCampaign?/:subRegistrationType?"
        component={Register}
      />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/password-reset/:token" component={UpdatePassword} />
      <Route path="/verify-token" component={VerifyToken} />
      <RequireAuth path={`/${appId}`}>
        <ForceRedirectToDefaultApplication />
      </RequireAuth>
    </Shell>
  )
}

export function getRedirect({ location, user, isFirstTimeVerifiedLogin }) {
  const origin = appShellUtil.getAppDotOrigin()
  const query = getQueryString(location.search)
  const registrationCampaign = getRegistrationCampaignConfiguration(
    user.registrationCampaign
  )

  if (
    isFirstTimeVerifiedLogin &&
    registrationCampaign.firstTimeLoginRedirectPath
  ) {
    return origin + registrationCampaign.firstTimeLoginRedirectPath + query
  }

  const app = user.organizationDefaultApplication || 'onboard'

  return `${origin}/${app}${query}`
}

function getQueryString(queryString) {
  return isUnsupportedRedirectPath(queryString) ? '' : queryString
}

function isUnsupportedRedirectPath(queryString) {
  return (
    queryString &&
    ['white-label-login', 'white-label-login-page', 'home'].some((b) =>
      queryString.includes(b.toLowerCase())
    )
  )
}
