import createInjectors from '@labelinsight/utils/createInjectors'
import createStore from './createStore'
import { makeRootReducer } from './reducers'

const store = createStore({})
window.__STORE__ = store

const { inject, cancel } = createInjectors({
  store,
  makeRootReducer,
})

export { inject, cancel }
export default store
