import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import vars from '@labelinsight/components/styles'

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 6rem;

  > div {
    width: 100%;
    max-width: 390px;
    padding: 3rem;
    background: ${vars.marketingPanelBackgroundColor};
    border: ${vars.cardBorderWidth} solid ${vars.cardBorderColor};
    border-radius: ${vars.inputBorderRadius};
  }
`

const Header = styled.header`
  margin-bottom: 3rem;

  > h4 {
    padding: 0 0 1rem;
    margin: 0 0 1rem;
    font-size: 2rem;
    font-weight: 700;
  }
`

const MarketingPanel = ({ title, children, className }) => (
  <Container className={`MarketingPanel ${className}`}>
    <div>
      {title && (
        <Header>
          <h4>{title}</h4>
        </Header>
      )}
      {children}
    </div>
  </Container>
)

MarketingPanel.defaultProps = {
  className: '',
}

MarketingPanel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
}

export default MarketingPanel
