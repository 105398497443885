import ReduxModule from 'li-redux-module'

const app = new ReduxModule('app')
const { types, creators } = app

const initialState = {
  isFirstTimeVerifiedLogin: false,
}

app.create('setIsFirstTimeVerifiedLogin', ['isFirstTimeVerifiedLogin'])

const reducer = app.reducer(initialState)

export { types, creators, reducer }
export default app
