import qs from 'qs'
import { put } from 'redux-saga/effects'
import { alert as appShellAlertThunk } from '@labelinsight/app-shell'
import { createSagas } from '@labelinsight/utils/alert'
import { createRouters } from '@labelinsight/utils/routing'
import { inject } from '$store'

export const { routeWithRedux } = createRouters({
  inject,
  cancel() {},
})

export const { onError, onSuccess } = createSagas(put, appShellAlertThunk, {
  logErrors: true,
  errorDefaults: {},
  successDefaults: {},
})

export const parseQueryStringWithoutDecoder = (search) =>
  qs.parse(search.replace(/^\?/, ''), {
    decoder: (x) => x,
  })
