import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { alert, _auth as auth } from '@labelinsight/app-shell'
import Button from '@labelinsight/components/Button'
import { Container as Field, Label, Input } from '@labelinsight/components/Form'

import { creators } from '$store/app'
import MarketingPanel from '$components/MarketingPanel'
import history from '$history'

const mapDispatchToProps = (dispatch) => ({
  onSuccess(verificationInfo) {
    dispatch(creators.setIsFirstTimeVerifiedLogin(true))
    dispatch(
      alert({
        kind: 'success',
        show: true,
        title: 'Success!',
        message: verificationInfo.alreadyVerified
          ? 'Email account already verified.'
          : 'Email verification successful.',
      })
    )
  },
  onError(error) {
    console.error(error)
    dispatch(
      alert({
        kind: 'danger',
        show: true,
        title: 'Something went wrong',
        message:
          'The token provided is incorrect or has expired. Please log in again to receive another verification code.',
      })
    )
  },
})

VerifyToken.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
}

export function VerifyToken({ onSuccess, onError }) {
  const [token, setToken] = useState('')
  const [submitted, setSubmitted] = useState(false)

  async function onSubmit() {
    try {
      setSubmitted(true)
      const verificationInfo = await auth.verify(token)
      onSuccess(verificationInfo)
    } catch (error) {
      onError(error)
    } finally {
      history.push('/login')
    }
  }

  return (
    <MarketingPanel title="Verify Your Email">
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <Field>
          <Label htmlFor="home-verificationToken">Verification Code</Label>
          <Input
            id="home-verificationToken"
            value={token}
            onChange={(e) => {
              setToken(e.target.value)
            }}
          />
        </Field>
        <Field>
          <Button kind="primary" size="large" disabled={!token || submitted}>
            Submit
          </Button>
        </Field>
      </form>
    </MarketingPanel>
  )
}

export default connect(null, mapDispatchToProps)(VerifyToken)
