import 'core-js'
import React from 'react'
// while the docs say to use react-dom/client,
// we are using a bundled version of react-dom provided via build-tools
// which includes createRoot
import * as ReactDOM from 'react-dom'
import store from './store'
import App from './App'
import routes from './routes'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(<App store={store} router={routes(store)} />)

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept(
    './App',
    root.render(<App store={store} router={routes(store)} />)
  )
}
