import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import {
  initialize as initializeAnalytics,
  middleware as analyticsMiddleware,
} from 'li-analytics'
import { initialize, middleware as errorLogger } from 'li-error-logger/lib'
import { _auth } from '@labelinsight/app-shell'

import config from '$config'
import history from '$history'
import makeRootReducer from './reducers'
import { updateLocation } from './location'

export default (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware()
  const middleware = [thunk, sagaMiddleware]

  const enhancers = []

  if (process.env.NODE_ENV !== 'test') {
    initializeAnalytics(config.gtm)
    middleware.push(analyticsMiddleware)

    if (!process.env.REACT_APP_LOCAL) {
      initialize(config.sentry, {
        environment: process.env.NODE_ENV,
        dataCallback(data) {
          data.user = _auth.getUser()
          return data
        },
        release: process.env.SENTRY_RELEASE,
      })

      middleware.unshift(errorLogger)
    }
  }

  if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.devToolsExtension

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension())
    }

    middleware.push(require('redux-logger').createLogger({ collapsed: true }))
  }

  const store = createStore(
    makeRootReducer(),
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  )

  store.asyncReducers = {}
  store.runSaga = sagaMiddleware.run
  store.unsubscribeHistory = history.listen(updateLocation(store))

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const reducers = require('./reducers').default
      store.replaceReducer(reducers(store.asyncReducers))
    })
  }

  return store
}
